import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["password", "toggler"]

  showHidePassword() {
    let password = this.passwordTarget;
    let toggler = this.togglerTarget;
    let icon = toggler.children[0];

    if (password.type == 'password') {
      password.setAttribute('type', 'text');
      icon.classList.add('fa-eye-slash');
    } else {
      icon.classList.remove('fa-eye-slash');
      password.setAttribute('type', 'password');
    }
  }
}
