const withActiveSession = !!document.querySelector("meta[name='timeout']")

if (withActiveSession) {
  const frequencyInSeconds = 900; // Equivalent of 15 minutes in seconds

  function autoSessionTimeout() {
    let request = new XMLHttpRequest();
    request.onload = function (event) {
      var status = event.target.status;
      var response = event.target.response;

      console.log(response)
      // if the remaining valid time for the current user session is less than or equals to 0 seconds.
      if (status === 200 && (response <= 0)) {
        window.location.href = '/timeout';
      }
    };
    request.open('GET', '/check_session_timeout', true);
    request.responseType = 'json';
    request.send();
    setTimeout(autoSessionTimeout, (frequencyInSeconds * 1000));
  }

  window.setTimeout(autoSessionTimeout, (frequencyInSeconds * 1000));
}
