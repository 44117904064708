import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = [ "form", "baseExchangeRate", "client", "workStatus", "requiredHoursField", "requiredHoursFieldTemplate" ]

    connect() {
        if (this.hasFormTarget) { this.toggleRequiredHours() }
    }

    toggleRequiredHours(e) {
        const workStatus = this.workStatusTarget.options[this.workStatusTarget.selectedIndex].text
        console.log('workStatus', workStatus)

        if (workStatus == 'Part-Time') {
            this.requiredHoursFieldTarget.innerHTML = this.requiredHoursFieldTemplateTarget.innerHTML
        } else {
            document.querySelector('#required-hours-field-wrapper').innerHTML = ''
        }
    }

    // New Stimulus Methods Base from Migrating Jquery from contractors.js

    copyApproverEmailFromClient() {
        fetch('/admin/clients/' + document.querySelector('select[name="contractor[client_id]"]').value + '.json')
            .then(response => response.json())
            .then(function(data) {
              document.querySelector('input[name="contractor[approver_email]"]').value = data.email
              let countries = document.querySelector('select[name="contractor[country_id]"]')

              Array.from(countries.options).forEach((option, i) => {
                if (option.value === String(data.country_id)) {
                    countries.selectedIndex = i;
                }
              });
            })
            .catch(error => {
                alert(error)
            })
    }

    onClientIdChanged(event) {
        fetch('/admin/clients/' + this.clientTarget.options[this.clientTarget.selectedIndex].value + '/new-contractor-base-exchange-rate.json')
            .then(response => response.json())
            .then(function(data) {
              // document.querySelector('input[name="contractor[base_exchange_rate]"]').value = data.base_exchange_rate
            })
            .catch(error => {
                alert(error)
            })
    }

    onRecruiterIdCanged(event) {
        document.querySelector(
            'select[name="contractor[recruitments_attributes][0][job_order_assigned_to]"]'
        ).value = event.target.value
    }

    showCharts(event) {
        var btn = event.target
        var charts = document.getElementById('cancelledCharts')
        if (btn.innerHTML == 'Show Charts') {
            charts.classList.remove('d-none')
            btn.innerHTML = 'Hide Charts'
        } else {
            charts.classList.add('d-none')
            btn.innerHTML = 'Show Charts'
        }
    }

    showRecruiterCharts(event) {
        var btn = event.target
        var charts = document.getElementById('cancelledByRecruiter')
        if (btn.innerHTML == 'Show Recruiter Statistics') {
            charts.classList.remove('d-none')
            btn.innerHTML = 'Hide Recruiter Statistics'
        } else {
            charts.classList.add('d-none')
            btn.innerHTML = 'Show Recruiter Statistics'
        }
    }

    onEditCancellation(event) {
        console.log(event);
        event.preventDefault();
        var cancellationDiv = document.getElementById('contractorCancellation')
        var cancellationInputs = cancellationDiv.querySelectorAll("input, select, checkbox, textarea")
        var check = confirm("Do you want to update the Cancellation Details?");

        if(check == true) {
            cancellationDiv.classList.remove('d-none')
            cancellationInputs.forEach(function (item, index) {
                item.disabled = false
            })

            document.getElementById('contractor_contractor_cancellation_type_id').focus();
        }
    }

    onEndDateChanged(event) {
        event.preventDefault();

        var cancellationDiv = document.getElementById('contractorCancellation')
        var cancellationInputs = cancellationDiv.querySelectorAll("input, select, checkbox, textarea")

        if (event.target.value !== '') {
            if (event.target.type == undefined) {
                var check = true;
                event.target.classList.add('d-none')
            } else {
                var check = confirm("Do you want to add Cancellation Type and Notes?");
            }

            if ( check == true ) {
                cancellationDiv.classList.remove('d-none')
                cancellationInputs.forEach(function (item, index) {
                    item.disabled = false
                })

                document.getElementById('contractor_contractor_cancellation_type_id').focus();
            }
        }
    }

    contractorOnTrialChanged(event) {
        console.log(event)
        let onTrialCheckbox = event.target
        let isTrial = document.querySelector('input[name="contractor[should_create_invoice]"]')
        let endDate = document.querySelector('input[name="contractor[end_date]"]')
        let trialTerms = document.querySelector('fieldset#trial-terms')
        let shouldCreateInvoice = document.querySelector('input[name="contractor[should_create_invoice]"]')
        let regularizationFields = document.getElementById('regularization-fields')

        if (onTrialCheckbox.checked) {
            endDate.value = endDate.value;
        } else {
            endDate.value = '';
        }

        if (trialTerms) {
            if (onTrialCheckbox.checked) {
                trialTerms.classList.remove('d-none')
                if (regularizationFields) { regularizationFields.classList.add('d-none') }
                if (isTrial) { shouldCreateInvoice.checked = false; }
            } else {
                trialTerms.classList.add('d-none')
                if (regularizationFields) { regularizationFields.classList.remove('d-none') }
                if (isTrial) { shouldCreateInvoice.checked = true; }
            }
        }
    }

    onRegularizationChanged(event) {
        let regularizationEffectiveDate = document.querySelector('input[name="contractor[regularization_attributes][effective_date]"]')

        if (event.target.checked) {
            regularizationEffectiveDate.parentElement.classList.remove('d-none')
        } else {
            regularizationEffectiveDate.parentElement.classList.add('d-none')
        }
    }

    onEditParticulars(event) {
        console.log(event);
        event.preventDefault();
        let contractorRate = document.querySelector('input[name="contractor[rate]"]')
        let requiredHours = document.querySelector('input[name="contractor[required_hours]"]')

        contractorRate.disabled = false;
        contractorRate.focus();

        document.querySelector('input[name="contractor[compensation]"]').disabled = false;
        document.querySelector('select[name="contractor[work_status_id]"]').disabled = false;
        document.querySelector('input[name="contractor[base_exchange_rate]"]').disabled = false;

        if (requiredHours) { requiredHours.disabled = false; }

        event.target.style.opacity = 0;
    }

    // New Stimulus Methods Base from Migrating Jquery from custom.js

    extendContractorsTrial(event) {
        event.preventDefault();
        let btn = event.target
        let term_id = document.getElementById('termID').value
        let start_date = document.getElementById('termStartDate').value
        let end_date = document.getElementById('termEndDate').value
        const authenticityToken = document.querySelector('meta[name="csrf-token"]').content
        let data = {
            term_id: term_id,
            start_date: start_date,
            end_date: end_date
        }

        btn.disabled = true
        fetch('/admin/contractors/extend-trial', {
            method: 'POST',
            headers: {
              'Accept': 'text/javascript',
              'Content-Type': 'application/json',
              'X-CSRF-Token': authenticityToken
            },
            body: JSON.stringify(data),
        }).then(
            Turbo.visit(location.toString())
        ).catch(error => {
            alert(error);
            btn.disabled = false;
        })
    }

    checkIfPreviouslyCancelledStaff(event) {
        event.preventDefault();

        let name = event.target.value;
        let textField = event.target
        let data = { name: name }
        const authenticityToken = document.querySelector('meta[name="csrf-token"]').content
        var duration = 1000;

        let remarks = document.getElementById('contractorNameRemarks')
        let cancelledStaffLink = document.getElementById('cancelledStaffLink')
        let cancelledStaffTitle = document.getElementById('cancelledStaffTitle')
        let checkSpinner = document.getElementById('contractorCheckingSpinner')
        if (name == null || name == "") {
            return
        }

        clearTimeout(textField._timer);
        textField._timer = setTimeout(()=>{
            cancelledStaffLink.innerText = ""
            cancelledStaffTitle.innerText = ""
            remarks.innerText = ""

            checkSpinner.classList.remove('d-none')
            fetch('/admin/contractors/check_if_previously_cancelled_staff', {
                method: 'POST',
                headers: {
                  'Accept': 'text/javascript',
                  'Content-Type': 'application/json',
                  'X-CSRF-Token': authenticityToken
                },
                body: JSON.stringify(data),
            })
            .then(response => response.json())
            .then(function(data) {
                setTimeout(()=>{
                    if (data.name == null) {
                        remarks.innerText = "Cleared. No records found!"
                        textField.classList.remove('is-invalid')
                        remarks.classList.remove('text-danger')
                        textField.classList.add('is-valid')
                    } else {
                        cancelledStaffLink.href = "/admin/contractors/" + data.id;
                        cancelledStaffTitle.innerText = "Previous engagement cancellation found! "
                        cancelledStaffLink.innerText = "(" + data.name + " | " + data.client_name + "| End Date: " + data.end_date
                        if (data.reason !== null) {
                            cancelledStaffLink.innerText += " | " + "Reason: " + data.reason + ")"
                        }
                        remarks.classList.add('text-danger')
                        textField.classList.remove('is-valid')
                    }

                    checkSpinner.classList.add('d-none')
                }, duration)

            })
        }, 500);
    }

    formatBankAccountNumberInput(event) {
        let value = event.target.value
        let numbers = value.replace(/[^0-9 ]/g, "");
        console.log(numbers)

        event.target.value = numbers;
    }

    downcaseEmailAddress(event) {
        event.target.value = event.target.value.toLowerCase();
    }

    toggleWorkDays(event) {
        console.log(event.target)
        var btnValue = event.target.innerHTML
        var wsArr = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday']

        if (btnValue.includes('M - F')) {
            var arr = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday']
        } else if (btnValue.includes('M W F')) {
            var arr = ['monday', 'wednesday', 'friday']
        } else if (btnValue.includes('T TH S')) {
            var arr = ['tuesday', 'thursday', 'saturday']
        } else if (btnValue.includes('M - Saturday')) {
            var arr = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday']
        }

        arr.forEach(function(day) {
            document.getElementById(day).checked = true;
        })

        var unchecked = wsArr.filter(val => !arr.includes(val));

        unchecked.forEach(function(day) {
            document.getElementById(day).checked = false;
        })
    }

    btnEditWorkdays(event) {
        var wsArr = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday']
        wsArr.forEach(function(day) {
            document.getElementById(day).disabled = false;
        })

        document.getElementById('dayToggles').classList.remove('d-none')
        document.getElementById('btnEditWorkdays').classList.add('d-none')
    }

    toggleLeaveRequestApprover(event) {
        const hasLeaveCreditsField = document.getElementById('contractor_has_leave_credits');
        const leaveRequestApproverContainer = document.getElementById('leaveRequestApproverContainer');

        if (hasLeaveCreditsField.checked) {
            leaveRequestApproverContainer.classList.remove('d-none')
        } else {
            leaveRequestApproverContainer.classList.add('d-none')
        }
    }
}
